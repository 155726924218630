import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import mainNavVid from "../videos/main-nav.mp4";
import mouseVid from "../videos/mouse2.mp4";
import calibVid from "../videos/calibrate.mp4";
import { BlockEl, FullLengthFlexBox, Block280 } from "../components/layout/Styles";
import Img from "gatsby-image";
import { GatsbyImage } from "gatsby-plugin-image";
import { ExtLinkBtn, LinkBtn } from "../components/layout/ui.js";
export const pageQuery = graphql`
  query {
    allFile(filter: {relativeDirectory: {eq: "general"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 280)
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title={props.pageContext.frontmatter.title} mdxType="SEO" />
    <h1>{`How to Use Eyechart.Online`}</h1>
    <hr></hr>
    <h2>{`1. Setup Your Test Room`}</h2>
    <hr></hr>
    <h3>{`You will need the following:`}</h3>
    <hr></hr>
    <h3>{`PC, Laptop or Tablet with a Browser`}</h3>
    <FullLengthFlexBox mdxType="FullLengthFlexBox">
  {props.data.allFile.nodes.map((node, idx) => {
        const regexPatt = /computer-./;
        return regexPatt.test(node.childImageSharp.gatsbyImageData.images.fallback.src) ? <Block280 key={`comp-${idx}`} mdxType="Block280">
          <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt="" mdxType="GatsbyImage" />
        </Block280> : null;
      })}
    </FullLengthFlexBox>
    <p>{`This can any device running Windows, MacOS, Linux, Android or IOS. Even a small raspberry pi works perfectly.`}</p>
    <hr></hr>
    <h3>{`A wall bracket for mounting the monitor against the wall (optional)`}</h3>
    <FullLengthFlexBox mdxType="FullLengthFlexBox">
  {props.data.allFile.nodes.map((node, idx) => {
        const regexPatt = /wall-bracket./;
        return regexPatt.test(node.childImageSharp.gatsbyImageData.images.fallback.src) ? <Block280 key={`comp-${idx}`} mdxType="Block280">
          <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt="" mdxType="GatsbyImage" />
        </Block280> : null;
      })}
    </FullLengthFlexBox>
    <p>{`Mount the monitor against the wall using a wall bracket and start the Eyechart.Online application
by opening google chrome and browsing to Eyechart.Online. Sign up or log in.`}</p>
    <hr></hr>
    <h2>{`2. Calibrate the screen and set the test distance`}</h2>
    <BlockEl as="video" autoPlay loop mdxType="BlockEl">
  <source src={calibVid} type="video/mp4" />
    </BlockEl>
    <p>{`When using the application for the first time, it is very important to
calibrate the display screen and specify the correct viewing distance of
the display. This ensures that the optotypes are displayed at the
correct size for your particular display resolution at the patient’s
viewing distance. This only needs to be set once and the settings will
be remembered the next time the application is launched from the same
device in the same browser. It needs only to be set again if you change
your monitor or if you are testing at a different distance than from
before. This would generally not change in a fixed examination room
setup.`}</p>
    <h2>{`3. User Interface`}</h2>
    <p>{`The User Interface for Eyechart.Online was made to be as simple and intuitive as possible.
It is easily controlled with a small wireless keyboard, a regular mouse, an air mouse or our
dedicated remote control (coming soon).`}</p>
    <BlockEl as="video" autoPlay loop mdxType="BlockEl">
  <source src={mainNavVid} type="video/mp4" />
    </BlockEl>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The main navigation panel is on the left hand side of the screen`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`This contains all of the main charts or categories`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Clicking on one of the categories on the left brings up the options menu for that particular
chart or category in the right hand side panel`}</strong></li>
    </ul>
    <hr></hr>
    <h2>{`4. Working with a mouse`}</h2>
    <p>{`Although you can navigate through the app and change charts as show
above, this can be a little cumbersome and the small icons can be
difficult to see if you are meters away from the screen. For this reason
we made it much simpler to navigate with the mouse by implementing a
large radial pop up menu.`}</p>
    <BlockEl as="video" autoPlay loop mdxType="BlockEl">
  <source src={mouseVid} type="video/mp4" />
    </BlockEl>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Simply right click anywhere to bring up the radial menu with all the chart categories`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Click on a category`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Radial menu will change to show the options for the selected category`}</strong></li>
    </ul>
    <hr></hr>
    <h2>{`5. Working with a keyboard`}</h2>
    <p>{`Almost every feature, chart, action or button has an associated keyboard
shortcut key. The quickest and easiest way to use the application is with
a small wireless keyboard like one of these which is readily available at
any Makro, Game, Computer or Gadget shop. Simply plug the USB receiver into
your computer and you can remotely control the application. `}</p>
    <ExtLinkBtn href="https://drive.google.com/open?id=1LpCmexA5Vlnbrmm3mRgrKvjSyQHxi6OD" target="_blank" rel="noopener noreferrer" style={{
      margin: "auto",
      maxWidth: "50%",
      textAlign: "center"
    }} mdxType="ExtLinkBtn">
  Get a printable pdf with all keyboard shortcuts here
    </ExtLinkBtn>,
    <FullLengthFlexBox mdxType="FullLengthFlexBox">
  {props.data.allFile.nodes.map((node, idx) => {
        const regexPatt = /wireless-keyboard-./;
        return regexPatt.test(node.childImageSharp.gatsbyImageData.images.fallback.src) ? <Block280 key={`wk-${idx}`} mdxType="Block280">
          <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt="" mdxType="GatsbyImage" />
        </Block280> : null;
      })}
    </FullLengthFlexBox>
    <hr></hr>
    <h2>{`Dedicated Remote Control`}</h2>
    <p>{`Although Eyechart.Online is really simple to use with a small wireless keyboard as desribed
above, we recognise that you may prefer just using a dedicated remote control. We are in the
process of creating a custom remote control for use with the application. We estimate to have
it ready for purchase around May 2020 and will update you as soon as it is ready.`}</p>
    <LinkBtn to="/get-started" style={{
      margin: "80px auto",
      maxWidth: "50%",
      textAlign: "center"
    }} mdxType="LinkBtn">
  Start the App >>
    </LinkBtn>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      